import axiosServices from './request';

const UserApi = axiosServices('/user');

export default {
  // 登录
  login: UserApi('post', 'user/loginByPhone'),
  loginByPwd: UserApi('post', 'user/loginByPwd'),

  // 获取用户信息
  getUserInfo: UserApi('post', 'user/getUserInfo'),

  // 出版社系列
  getPublishList: UserApi('post', 'publish/getPublishList'),
  // 新增出版社
  addPublish: UserApi('post', 'publish/addPublish'),
  deletePublish: UserApi('post', 'publish/deletePublish'),
  getAgentDetail: UserApi('get', 'publish/getAgentDetail'),
  getPublishVRBooks: UserApi('post', '/publish/getPublishVRBooks'),
  getBannerList: UserApi('get', '/publish/getBannerList'),
  saveBanner: UserApi('post', '/publish/saveBanner'),
  deleteBanner: UserApi('post', '/publish/deleteBanner'),

  //上面的接口待删除

  // 发送登录短信
  sendPhoneMessage: UserApi('post', 'user/sendPhoneMessage'),
  // 登录
  loginByPhone: UserApi('post', 'user/loginByPhone'),
  // 联系我们
  websiteConcat: UserApi('post', 'erp/websiteConcat')

};
