/*
 * @Author: weijiali
 * @Date: 2022-04-14 13:18:16
 * @LastEditors: weijiali
 * @LastEditTime: 2022-10-09 14:06:56
 */
import axiosServices from './request';

const BookModel = axiosServices('book');

export default {

  // 获取图书列表（分页）
  getBookListPage: BookModel('post', 'book/getBookListPage'),

  // 新增图书信息
  addBookInfo: BookModel('post', 'book/addBookInfo'),

  // 获取图书详情
  getBookDetail: BookModel('get', 'book/getBookDetail'),

  // 修改图书信息
  modifyBookDetail: BookModel('post', 'book/modifyBookDetail'),

  // 删除图书信息
  deleteBook: BookModel('post', 'book/deleteBook'),

  // 获取类型列表
  getAllTypeData: BookModel('get', 'categories/getAllTypeData'),

  // 批量新增章节
  addChapters: BookModel('post', 'chapters/addChapters'),

  // 获取章节列表
  getChaptersList: BookModel('post', 'chapters/getChaptersList'),

  // 更新章节
  updateChapters: BookModel('post', 'chapters/updateChapters'),

  // 分页查询图书下的题目
  queryQa: BookModel('post', 'qa/query'),

  // 删除题目
  deleteQa: BookModel('post', 'qa/delete'),

  // 新增题目
  insertQa: BookModel('post', 'qa/insert'),

  // 修改题目
  updateQa: BookModel('post', 'qa/update'),

  getBookNftList: BookModel('get', 'book/getBookNftList'),
  addBookNft: BookModel('post', 'book/addBookNft'),
  deleteBookNft: BookModel('post', 'book/deleteBookNft'),
  saveBookVrRes: BookModel('post', 'book/saveBookVrRes'),
  getBookRes: BookModel('get', 'book/getBookRes')

};
