import axiosServices from './request';

const AppModel = axiosServices('app');

export default {
  // 图书订购像狗
  // 获取图书列表（分页）
  getBookOrderListPage: AppModel('post', 'bookOrder/getBookOrderListPage'),

  // 新增图订购信息
  addBookOrder: AppModel('post', 'bookOrder/addBookOrder'),

  // 获取图书订购详情
  getBookOrderDetail: AppModel('get', 'bookOrder/getBookOrderDetail'),

  // 修改图书订购信息
  modifyBookOrder: AppModel('post', 'bookOrder/modifyBookOrder'),

  // 删除图书订购信息
  deleteBookOrder: AppModel('post', 'bookOrder/deleteBookOrder'),

  // 图书订购上下架信息
  updateSaleStatus: AppModel('post', 'bookOrder/updateSaleStatus'),

  // 获取简介版本图书订购列表
  getSelectAppList: AppModel('post', 'app/getSelectAppList'),

  // 优惠券相关
  // 新增优惠券
  addCoupon: AppModel('post', 'coupon/addCoupon'),

  // 获取优惠券信息
  getCouponInfo: AppModel('get', 'coupon/getCouponInfo'),

  // 获取优惠券列表
  getCouponListPage: AppModel('post', 'coupon/getCouponListPage'),

  // 删除优惠券
  deleteCoupon: AppModel('post', 'coupon/deleteCoupon'),

  // 修改优惠券信息
  modifyCouponDetail: AppModel('post', 'coupon/modifyCouponDetail'),

  //  获取商品列表（不分页）
  getAppList: AppModel('get', 'app/getAppList'),

  /** 获取藏品相关的优惠券信息 */
  getNftWithCoupon: AppModel('get', 'coupon/getNftWithCoupon'),

  /** 绑定优惠券到藏品上 */
  bindCouponWithNft: AppModel('post', 'coupon/bindCouponWithNft'),

  /** 通过商品id获取优惠券信息 */
  getCouponByAppId: AppModel('get', 'coupon/getCouponByAppId'),

  /** 取消藏品绑定的优惠券 */
  removeCouponWithNft: AppModel('post', 'coupon/removeCouponWithNft')
};
