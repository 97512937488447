/*
 * @Author: weijiali
 * @Date: 2022-04-18 19:19:07
 * @LastEditors: weijiali
 * @LastEditTime: 2022-08-23 19:07:56
 */
import Immutable from 'seamless-immutable';
import bookApi from '@/api/bookApi';
import vrmodelApi from '@/api/vrmodelApi';
import readerApi from '@/api/readerApi';
import appApi from '@/api/appApi';

export default {
  namespace: 'typeData',
  state: Immutable({
    typeData: {},
    vrTypeData: {},
    seriesData: [],
    channelList: [],
    channelData: [],
    nftTypeList: [],
    fakeReaderList: [],
    appList: []
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data }),
    updateChannel: ((state, { data: channelList }) => {
      try {
        const channelData = {};
        channelList.forEach(({ channelId, channelName }) => {
          channelData[channelId] = channelName;
        });
        return state.merge({
          channelList,
          channelData
        });
      } catch(e) {
        console.error(e);
      }
    }),
    updateNftTypeList: ((state, { data: nftTypeList }) => {
      try {
        // const nftTypeList = nftTypeData?.recordList;
        return state.merge({
          nftTypeList
        });
      } catch(e) {
        console.error(e);
      }
    }),
    updateFakeReaderList: ((state, { data: fakeReaderList }) => {
      try {
        return state.merge({
          fakeReaderList
        });
      } catch(e) {
        console.error(e);
      }
    }),
    updateBookOrderList: ((state, { data: appList }) => {
      try {
        return state.merge({
          appList
        });
      } catch(e) {
        console.error(e);
      }
    })
  },
  actions: {
    getAllTypeData: function(payload) {
      return {
        type: 'typeData/update/getAllTypeData',
        field: 'typeData',
        promise: bookApi.getAllTypeData(payload)
      };
    },
    getVrAllTypeData: function(payload) {
      return {
        type: 'typeData/update/getVrAllTypeData',
        field: 'vrTypeData',
        promise: vrmodelApi.getVrAllTypeData(payload)
      };
    },
    getSeriesData: function(payload) {
      return {
        type: 'typeData/update/getSeriesData',
        field: 'seriesData',
        promise: vrmodelApi.getSeriesList(payload)
      };
    },
    getChannelList: function(payload) {
      return {
        type: 'typeData/updateChannel',
        promise: readerApi.getChannelList(payload)
      };
    },
    getNftTypeList: function(payload) {
      return {
        type: 'typeData/updateNftTypeList',
        promise: vrmodelApi.getPcCutNftType(payload)
      };
    },
    getFakeReaderList: function(payload) {
      return {
        type: 'typeData/updateFakeReaderList',
        promise: readerApi.getFakeReaderList()
      };
    },
    getBookOrderList: function(payload) {
      return {
        type: 'typeData/updateBookOrderList',
        promise: appApi.getAppList()
      };
    }
  }

};

