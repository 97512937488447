
export const isDevelopment = process.env.NODE_ENV !== 'production';

export const BASE_API_ROOT = window.location.origin + '/api';

const ENV = window.location.origin.includes('metabookstore.com') ? 'PROD' : 'TEST';

// 系统角色
const systemCode = 'metabook_manage';

// 系统 ID
const systemId = '1';

const config = {
  isDevelopment,
  industryCaseId: systemId,
  systemCodeText: '元宇宙事业部',
  COOKIE: `${systemCode}_token`,
  iconfontURL: '//at.alicdn.com/t/font_1542801_kdyfn2gn11i.js',     // 字体图标库链接
  ENV
};

export default config;
