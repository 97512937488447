/*
 * @Author: weijiali
 * @Date: 2022-03-17 14:24:48
 * @LastEditors: weijiali
 * @LastEditTime: 2022-10-12 16:27:19
 */
import axiosServices from './request';

const UserApi = axiosServices('activity/v1.0');
const ReaderApi = axiosServices('reader');

export default {
// TODO: 旧的API待删除
  // 获取年级列表
  getGradeList: UserApi('get', 'read/listGrade'),

  // 获取学校列表
  getSchoolList: UserApi('get', 'read/listSchoolRptByPcloud'),

  // 班级统计列表
  getClassList: UserApi('get', 'read/listClassRptByPcloud'),

  // 设置广告开关
  adSwitchSetting: UserApi('post', 'banner/adSwitchSetting'),

  // 获取广告开关
  getAdSwitchSetting: UserApi('get', 'banner/getAdSwitchSetting'),

  // 修改banner图
  updateBanner: UserApi('post', 'banner/save'),

  // 获取banner列表
  getBannerList: UserApi('get', 'banner/getList'),

  // 获取引流配置
  getInviteConfig: UserApi('get', 'activityInviteConfig/getList'),

  // 保存引流配置
  updateInviteConfig: UserApi('post', 'activityInviteConfig/update'),

  // 保存引流配置
  deleteInviteConfig: UserApi('get', 'activityInviteConfig/softDeleteById'),

  // 直播配置
  updateLiveConfig: UserApi('post', 'activityLiveConfig/update'),

  // 获取直播配置
  getLiveConfig: UserApi('get', 'activityLiveConfig/getLiveConfig'),

  // 学习班级数据概览
  getSchoolClassOverviewStatistic: UserApi('get', 'read/getSchoolClassOverviewStatistic'),

  // 学生城市分布
  getStudentCityDistribute: UserApi('get', 'read/getStudentCityDistribute'),

  // 学生地区分布
  getStudentAreaDistribute: UserApi('get', 'read/getStudentAreaDistribute'),

  // 学生数据概览
  getStudentOverviewStatistic: UserApi('get', 'read/getStudentOverviewStatistic'),

  // 学校学生排行
  listSchoolRptByCity: UserApi('post', 'read/listSchoolRptByCity'),

  // 班级积分排行
  listClassRptByCity: UserApi('post', 'read/listClassRptByCity'),

  // 获取藏品类型下所有的交易记录
  getRecordByNftType: ReaderApi('post', 'nft/getRecordByNftType'),

  // 获取可以选择的渠道列表
  getChannelList: ReaderApi('get', 'channel/getChannelList'),

  // 获取渠道banner图
  getChannelBanners: ReaderApi('get', 'channel/getChannelPCBanners'),

  // 删除banner信息
  deleteBanner: ReaderApi('post', 'channel/deleteBanner'),

  // 修改banner信息
  modifyBannerInfo: ReaderApi('post', 'channel/modifyBannerInfo'),

  // 获取渠道banner信息详情
  getBannerInfo: ReaderApi('get', 'channel/getBannerInfo'),

  // 新增banner信息
  addChannelBanner: ReaderApi('post', 'channel/addChannelBanner'),

  // 获取虚拟用户列表（未设置密码，且有用户头像）
  getFakeReaderList: ReaderApi('post', 'reader/getFakeReaderList'),

  // 查询提现订单
  withdrawOrder: ReaderApi('post', 'wallet/withdrawOrder'),

  // 提现审核
  withdrawAudit: ReaderApi('post', 'wallet/withdrawAudit'),

  // 将提现审核通过的申请标记为已打款
  withdrawFinish: ReaderApi('post', 'wallet/withdrawFinish'),

  // 导出待打款名单
  withdrawExecl: ReaderApi('get', 'wallet/withdrawExecl'),

  // 用户可回购藏品
  queryCanRefundNft: ReaderApi('get', 'nft/queryCanRefundNft'),

  // 销毁用户藏品
  destroyCanRefundNft: ReaderApi('post', 'nft/destroyCanRefundNft')
};
