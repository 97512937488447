/*
 * @Author: weijiali
 * @Date: 2022-03-17 14:24:48
 * @LastEditors: weijiali
 * @LastEditTime: 2022-06-10 14:55:56
 */
import Utils from '@/utils/utils';

export const PAGES = {
  HOME: '',
  MANAGE: './manage'
};

// 获取带params路由
export function getPageUrl(page, params) {
  let url = page;
  Object.keys(params)
    .forEach(key => {
      if(url.indexOf(`:${key}`) > -1) {
        url = url.replace(`:${key}`, params[key]);
        delete params[key];
      }
    });
  return `${url}${Utils.formatQuery2QueryStr(params)}`;
}

export default PAGES;

