/*
 * @Author: weijiali
 * @Date: 2022-03-21 13:40:54
 * @LastEditors: weijiali
 * @LastEditTime: 2022-08-03 16:11:46
 */
import axiosServices from './request';

const VRModel = axiosServices('vrmodel');

export default {

  // 新增藏品类型
  addNft: VRModel('post', 'nft/addNftType'),

  // 获取NFT藏品类型列表
  getNftListPage: VRModel('post', 'nft/getNftTypeListPage'),

  // 获取NFT藏品类列表（不分页-精简信息）
  getPcCutNftType: VRModel('post', 'nft/getPcCutNftType'),

  // 修改藏品类型
  modifyNftType: VRModel('post', 'nft/modifyNftType'),

  // 删除NFT藏品类型
  deleteNftType: VRModel('post', 'nft/deleteNftType'),

  // 获取藏品交易记录
  getRecordByNft: VRModel('post', 'nft/getRecordByNft'),

  // 发行指定数量的NFT
  minting4Chain: VRModel('post', 'nft/minting4Chain'),

  // 获取NFT藏品类型信息
  getNftTypeInfo: VRModel('get', 'nft/getNftTypeInfo'),

  // 模型列表
  getVrModelList: VRModel('post', 'model/getVrModelList'),

  // 新增模型
  addVrModel: VRModel('post', 'model/addVrModel'),

  // 修改模型
  modifyVrModel: VRModel('post', 'model/modifyVrModel'),

  // 获取模型详情
  getVrModelDetail: VRModel('get', 'model/getVrModelDetail'),

  // 删除模型
  deleteVrModel: VRModel('post', 'model/deleteVrModel'),

  // 获取vr类型列表
  getVrAllTypeData: VRModel('get', 'model/getVrAllTypeData'),

  // 新增系列
  addSeries: VRModel('post', 'nft/addSeries'),

  // 获取系列列表
  getSeriesList: VRModel('get', 'nft/getSeriesList'),

  // 修改系列名称
  modifySeries: VRModel('post', 'nft/modifySeries'),

  // 删除系列
  deleteSeries: VRModel('post', 'nft/deleteSeries'),

  // 通过发行凭证获取流通记录
  getAssets: VRModel('post', 'nft/getAssets'),

  // 获取该藏品类型下蚂蚁链的交易记录
  getAntRecordByNftType: VRModel('post', 'nft/getAntRecordByNftType'),

  // 新增空投信息
  addAirdrop: VRModel('post', 'airdrop/addAirdrop'),

  // 获取空投列表信息
  getAirdropPage: VRModel('post', 'airdrop/getAirdropPage'),

  // 获取详情列表（空投明细）
  getAirdropDetailPage: VRModel('post', 'airdrop/getAirdropDetailPage'),

  // 追发空投
  addExtraAirdrop: VRModel('post', 'airdrop/addExtraAirdrop'),

  // 补发发送失败的用户空投
  addFailAirdrop: VRModel('post', 'airdrop/addFailAirdrop'),

  // 获取空投信息详情
  getAirdropInfo: VRModel('get', 'airdrop/getAirdropInfo'),

  // 获取盲盒开启统计记录列表
  getBlindBoxOpenStatistic: VRModel('get', 'blindbox/getBlindBoxOpenStatistic'),

  // 获取兑换码列表
  getCodeList: VRModel('post', '/exchange/getCodeList'),

  // 生成兑换码
  generate: VRModel('post', '/exchange/generate'),
  // 修改兑换码状态
  updateCodeStatus: VRModel('post', '/exchange/updateCodeStatus')
};
