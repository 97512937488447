import React from 'react';
import { Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Loadable from 'react-loadable';
import Loading from '@/components/Loading';

import zhCN from 'antd/lib/locale/zh_CN';

import './App.scss';

export default class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.Website = Loadable({
      loader: () => import('./website'),
      loading: () => <Loading />
    });
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    const { Website } = this;
    return (
      <ConfigProvider locale={zhCN}>
        <Switch>
          {
            this.props.children || <Website />
          }
        </Switch>
      </ConfigProvider>

    );
  }
}
