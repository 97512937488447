/**
 *  Date    : 2021/12/09
 *  Author  : weiLin
 *  Declare : 默认菜单
 */
import Utils from '@/utils/utils';

// 图标
export const MENUS_ICON = {
  dc: 'https://oss.5rs.me/oss/uploadfe/png/7f3535fe9e3aca0b20184c1806db80f9.png',
  digitalHuman: 'https://oss.5rs.me/oss/uploadfe/png/6c2de1cad767268142f253f894787917.png'
};

// 默认菜单
export const PRIVATE_DOMAIN_PERMISSIONS = [
  {
    iconType: MENUS_ICON.digitalHuman,
    id: 10,
    invalid: false,
    parentId: 0,
    name: '数字人',
    path: '/manage/digitalHuman/list'
  },
  {
    iconType: MENUS_ICON.dc,
    id: 2,
    invalid: false,
    parentId: 0,
    name: '数字藏品',
    path: '/manage/dc/list'
  },
  {
    iconType: MENUS_ICON.dc,
    id: 3,
    invalid: false,
    parentId: 2,
    name: '数字藏品',
    path: '/manage/dc/list'
  },
  // {
  //   iconType: MENUS_ICON.digitalHuman,
  //   id: 20,
  //   invalid: false,
  //   parentId: 0,
  //   name: '元宇宙图书',
  //   path: '/manage/bookConfig/list'
  // },
  // {
  //   iconType: MENUS_ICON.digitalHuman,
  //   id: 30,
  //   invalid: false,
  //   parentId: 0,
  //   name: 'AR图书',
  //   path: '/manage/ar/list'
  // },
  {
    iconType: MENUS_ICON.digitalHuman,
    id: 30,
    invalid: false,
    parentId: 0,
    name: 'AI智能创作',
    path: '/manage/ai/tts'
  },
  {
    iconType: MENUS_ICON.digitalHuman,
    id: 30,
    invalid: false,
    parentId: 0,
    name: 'AI聊天',
    path: '/manage/ai/chat'
  }
];

// 格式化配置路由
export default function setPermission(userInfo) {
  let [...routerPath] = PRIVATE_DOMAIN_PERMISSIONS;
  // 账号权限控制
  // if(['vr'].includes(userInfo.userName)) {
  //   routerPath = routerPath.filter(v => v.name === '3D模型库');
  // }
  return Utils.unFlatMap(routerPath);
}
