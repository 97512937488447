/*
 * @Author: weijiali
 * @Date: 2022-03-17 14:24:48
 * @LastEditors: weijiali
 * @LastEditTime: 2022-06-20 18:54:03
 */
import user from './user';
import projectSetting from './project-setting';
import common from './common';
// import activity from './activity';
import typeData from './typeData';
// import vrmodel from './vrmodel';

export default [
  user,
  common,
  projectSetting,
  // activity,
  typeData
  // vrmodel
];
