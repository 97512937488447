import axiosServices from './request';

const Convert = axiosServices('/convert/v1.0');

export default {

  // check 文件
  checkTick: Convert('get', 'leafFile/checkTick'),

  // upload file
  updateFinish: Convert('post', 'leafFile/updateFinish')

};
